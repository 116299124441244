import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import groupServices from './groupServices';

const initialState = {
  groups: [],
  activeGroups:[],
  totalRecord: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
};


export const addGroup = createAsyncThunk('addGroup', async (data, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await groupServices.addGroup(user.accesstoken, data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});
export const groupGet = createAsyncThunk(
  'groupGet',
  async ({pageno,branch,search,limit,sortField,sortOrder}, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await groupServices.group(user.accesstoken,pageno,limit,branch,search,sortField,sortOrder);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updateGroup = createAsyncThunk(
  'updateGroup',
  async ({ id, data }, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await groupServices.updateGroup(user.accesstoken, id, data);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const activeGroupGet = createAsyncThunk(
  'activeGroupGet',
  async (branch, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await groupServices.activeGroupGet(user.accesstoken,branch);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const groupSlice = createSlice({
  name: 'group',
  initialState,
  reducers: {
    resets: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addGroup.pending, (state) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(addGroup.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.isLoading = false;
        state.isError = false;
        state.groups = state.groups.concat({ ...action?.payload?.body });
      })
      .addCase(addGroup.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(groupGet.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(groupGet.fulfilled, (state, action) => {
        state.groups = action.payload.body;
        state.totalRecord = action.payload.total_record;
        state.isSuccess = true;
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(groupGet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateGroup.pending, (state) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(updateGroup.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.groups = state.groups.map((item) =>
          item._id === action?.payload?.body?._id ? action?.payload?.body : item
        );
      })
      .addCase(updateGroup.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(activeGroupGet.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(activeGroupGet.fulfilled, (state, action) => {
        state.activeGroups = action.payload.body;
        state.isSuccess = true;
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(activeGroupGet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { resets } = groupSlice.actions;

export default groupSlice.reducer;
