import axios from 'axios';

const BASE_URL = process.env.REACT_APP_HOST_API;

const addContact = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(`${BASE_URL}/admin/contact`, data, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Add Contact api failed');
};
const contact = async (token, pageno, limit, branch,search) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/contact?branch=${branch}&page=${pageno}&perPage=${limit}&search=${search ?? ''}`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error('Contact List api failed');
};
const updateContact = async (token, id, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(`${BASE_URL}/admin/contact/${id}`, data, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Updated Contact api failed');
};
const contactSingleGet = async (token,id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/contact/${id}`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error('Contact Single Get api failed');
};

const contactServices = {
  addContact,
  contact,
  updateContact,
  contactSingleGet
};

export default contactServices;
