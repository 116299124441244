import axios from 'axios';

const BASE_URL = process.env.REACT_APP_HOST_API;

const branchDetailGet = async (token,branch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/branch-details?branch=${branch}`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error('Branch Detail api failed');
};
const branchGet = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/branch`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error('Branch Get api failed');
};
const singleBranchGet = async (token,id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${BASE_URL}/admin/branch/single/${id}`, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Single Branch api failed');
};
const updateBranch = async (token, id, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(`${BASE_URL}/admin/branch/${id}`, data, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Updated branch api failed');
};


const branchServices = {
    branchDetailGet,
    branchGet,
    singleBranchGet,
    updateBranch
};

export default branchServices;