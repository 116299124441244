import axios from 'axios';

const BASE_URL = process.env.REACT_APP_HOST_API;

const incomeStatementGet = async (token, pageno, branch, limit, startDate, endDate,refStartDate,refEndDate) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/financialStatement/incomeStatement?branch=${branch}&page=${pageno}&perPage=${limit}&startDate=${
      startDate ?? ''
    }&endDate=${endDate ?? ''}&refStartDate=${refStartDate ?? ''}&refEndDate=${refEndDate ??""}`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error('Get Income Statement api failed');
};

const balanceSheetGet = async (token, pageno, branch, limit,balanceSheetPer,comparativeDataAs,accountType) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/financialStatement/balanceSheet?branch=${branch}&page=${pageno}&perPage=${limit}&balanceSheetPer=${
      balanceSheetPer ?? ''
    }&comparativeDataAs=${comparativeDataAs ?? ''}&accountType=${accountType ?? ''}`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error('Get Balance Sheet api failed');
};


const reportServices = {
  incomeStatementGet,
  balanceSheetGet
};

export default reportServices;
