import axios from 'axios';

const BASE_URL = process.env.REACT_APP_HOST_API;


const dashboardGet = async (token,branchId,year) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/dasboard?branchId=${branchId}&year=${year??""}`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error('Dasboard api failed');
};

const superAdminDashboardGet = async (token,year) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/superAdmin/dashboard?year=${year ?? ''}`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error('Super Admin Dasboard api failed');
};

const dashboardServices = {
    dashboardGet,
    superAdminDashboardGet
};

export default dashboardServices;
