import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
// import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/dashboard/app'));
const OverviewEcommercePage = lazy(() => import('src/pages/dashboard/ecommerce'));
const OverviewAnalyticsPage = lazy(() => import('src/pages/dashboard/analytics'));
const OverviewBankingPage = lazy(() => import('src/pages/dashboard/banking'));
const OverviewBookingPage = lazy(() => import('src/pages/dashboard/booking'));
const OverviewFilePage = lazy(() => import('src/pages/dashboard/file'));
// COMPANY
const CompanyPage = lazy(() => import('src/pages/dashboard/Company/list'));
// INVOICE
const InvoiceListPage = lazy(() => import('src/pages/dashboard/invoice/list'));
const InvoiceDetailsPage = lazy(() => import('src/pages/dashboard/invoice/details'));
const InvoiceCreatePage = lazy(() => import('src/pages/dashboard/invoice/new'));
const InvoiceEditPage = lazy(() => import('src/pages/dashboard/invoice/edit'));
// USER
const UserListPage = lazy(() => import('src/pages/dashboard/user/list'));
const UserCreatePage = lazy(() => import('src/pages/dashboard/user/new'));
const UserEditPage = lazy(() => import('src/pages/dashboard/user/edit'));

// CONTACTS
const ContactListPage = lazy(() => import('src/pages/dashboard/contact/list'));
const ContactCreatePage = lazy(() => import('src/pages/dashboard/contact/new'));
const ContactEditPage = lazy(() => import('src/pages/dashboard/contact/edit'));
// ACCOUNTING
const AccountCreatePage = lazy(() => import('src/pages/dashboard/accounting/new'));
const VATDeclarationPage = lazy(() => import('src/pages/dashboard/accounting/vat-declaration'));
const YearEndProcedurePage = lazy(() => import('src/pages/dashboard/accounting/year-end-procedure'));
const AccountingTransactionPage = lazy(() => import('src/pages/dashboard/accounting/list'));
const BalanceTaxRatePage = lazy(() => import('src/pages/dashboard/accounting/balance-tax-rate'));

// Report 
const VATReportPage = lazy(() => import('src/pages/dashboard/report/vat'));
const IncomeStatementPage = lazy(() => import('src/pages/dashboard/report/income-statement'));
const BalanceSheetListPage= lazy(() => import('src/pages/dashboard/report/balance-sheet'));
// Roles
const RolePage = lazy(() => import('src/pages/dashboard/role/list'));
const RoleCreatePage = lazy(() => import('src/pages/dashboard/role/add'));
// Premission 
const PremissioonPage = lazy(() => import('src/pages/dashboard/premission/list'));
// Mail Box
const MailBoxListPage = lazy(() => import('src/pages/dashboard/mailbox/list'));
// Setting
const SettingsPage = lazy(() => import('src/pages/dashboard/settings/settings'));
// Account Access
const AccountAccessPage = lazy(() => import('src/pages/dashboard/account-access/list'));

export const dashboardRoutes = [
  {
    path: 'admin',
    element: (
      // <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      //  </AuthGuard>
    ),
    children: [
      { path :'dashboard', element: <IndexPage />, index: true },
      { path: 'ecommerce', element: <OverviewEcommercePage /> },
      { path: 'analytics', element: <OverviewAnalyticsPage /> },
      { path: 'banking', element: <OverviewBankingPage /> },
      { path: 'booking', element: <OverviewBookingPage /> },
      { path: 'file', element: <OverviewFilePage /> },
      { path: 'settings', element: <SettingsPage /> },
      { path: 'mailbox', element: <MailBoxListPage /> },
      { path: 'company', element: <CompanyPage /> },
      { path: 'account-access', element: <AccountAccessPage /> },
      {
        path: 'user',
        children: [
          { element: <UserListPage />, index: true },
          { path: 'list', element: <UserListPage /> },
          { path: 'new', element: <UserCreatePage /> },
          { path: ':id/edit', element: <UserEditPage /> },],
      },
      {
        path: 'invoice',
        children: [
          { element: <InvoiceListPage />, index: true },
          { path: 'list', element: <InvoiceListPage /> },
          { path: ':id', element: <InvoiceDetailsPage /> },
          { path: ':id/edit', element: <InvoiceEditPage /> },
          { path: 'new', element: <InvoiceCreatePage /> },
        ],
      },
      {
        path: 'contact',
        children: [
          { element: <ContactListPage />, index: true },
          { path: 'list', element: <ContactListPage /> },
          { path: ':id/edit', element: <ContactEditPage /> },
          { path: 'new', element: <ContactCreatePage /> },
        ],
      },
      {
        path: 'accounting',
        children: [
          { element: <AccountingTransactionPage />, index: true },
          { path: 'transaction', element: <AccountingTransactionPage /> },
          { path: 'balance-tax-rate', element: <BalanceTaxRatePage /> },
          { path: 'new', element: <AccountCreatePage /> },
          { path: 'vat-declaration', element: <VATDeclarationPage /> },
          { path: 'year-end-procedure', element: <YearEndProcedurePage /> },
        ],
      },
      {
        path: 'reports',
        children: [
          { element: <IncomeStatementPage />, index: true },
          { path: 'income-statement', element: <IncomeStatementPage /> },
          { path: 'vat', element: <VATReportPage /> },
          { path: 'balance-sheet', element: <BalanceSheetListPage /> },
        ],
      },
      {
        path: 'roles&premissions',
        children: [
          {element: <RolePage />,index: true},
          { path: 'role/list', element: <RolePage /> },
          { path: 'role/new', element: <RoleCreatePage /> },
          { path: 'premissions/list', element: <PremissioonPage /> },
        ],
      },
    ],
  },
];
