import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

// auth
import { GuestGuard } from 'src/auth/guard';

// layouts
import CompactLayout from 'src/layouts/compact';
import AuthClassicLayout from 'src/layouts/auth/classic';

// components
import { SplashScreen } from 'src/components/loading-screen';


// JWT
const JwtLoginPage = lazy(() => import('src/pages/auth/jwt/login'));
const JwtRegisterPage = lazy(() => import('src/pages/auth/jwt/register'));
const JwtVerifyPage = lazy(() => import('src/pages/auth/jwt/verify'));
const JwtNewPasswordPage = lazy(() => import('src/pages/auth/jwt/new-password'));
const JwtForgotPasswordPage = lazy(() => import('src/pages/auth/jwt/forgot-password'));
const VerifyAccountPage = lazy(() => import('src/pages/auth/jwt/verify-Account'));

const authJwt = {
  path: '',
  element: (
    <GuestGuard>
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    </GuestGuard>
  ),
  children: [
    {
      path: 'dy882d',
      element: (
        <AuthClassicLayout>
          <JwtLoginPage />
        </AuthClassicLayout>
      ),
    },
    {
      path: 'llmidkes',
      element: (
        <AuthClassicLayout title="Manage the job more effectively with Accounty">
          <JwtRegisterPage />
        </AuthClassicLayout>
      ),
    },
    {
      element: (
        <CompactLayout>
          <Outlet />
        </CompactLayout>
      ),
      children: [
        { path: 'verify', element: <JwtVerifyPage /> },
        { path: 'new-password', element: <JwtNewPasswordPage /> },
        { path: 'forgot-password', element: <JwtForgotPasswordPage /> },
        { path: 'verify-account', element: <VerifyAccountPage /> },
      ],
    },
  ],
};

export const authRoutes = [
  {
    children: [authJwt],
  },
];
