import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
// theme
import { hideScroll } from 'src/theme/css';
// components
import Logo from 'src/components/logo';
import { paths } from 'src/routes/paths';
import { NavSectionMini } from 'src/components/nav-section';
import { usePathname } from 'src/routes/hooks';
//
import { NAV } from '../config-layout';
import { useNavData, useNavDataSuperAdmin } from './config-navigation';
import { NavToggleButton } from '../_common';
import { rolepremissionsGet } from 'src/redux/features/premission/premissionSlice';
import { billingTypeCheck } from 'src/redux/features/fiscal-year/fiscalYearSlice';

export default function NavMini() {

  const pathname = usePathname();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const navData = useNavData();
  const superAdminData = useNavDataSuperAdmin();

  const { currentUser } = useSelector((state) => state.auth);
  const {selectedBranch} = useSelector((state) => state.branch);
  const {billingType} =useSelector((state) => state.fiscalYear);
  const { premissionAgainstRole } = useSelector((state) => state.premission);

  useEffect(() => {
    if(currentUser?.userType != 1 && selectedBranch?._id){
      dispatch(billingTypeCheck(selectedBranch?._id))
    }
  }, [selectedBranch]);

  useEffect(() => {
    if (selectedBranch?.role) {
      dispatch(rolepremissionsGet(selectedBranch?.role?._id));
    }
  }, [selectedBranch]);

  const filterNavData = (navData, premissionAgainstRole, role) => {
    if (!role) {
      return navData;
    }
    const allowedTitles = new Set(
      premissionAgainstRole?.map((permission) => permission?.title?.toLowerCase())
    );
    return navData
      ?.map((section) => {
        const filteredItems = section?.items?.filter((item) => {
          if (item?.title?.toLowerCase() === 'dashboard'|| item?.title?.toLowerCase() == 'account access') return true;
          return allowedTitles?.has(item.title.toLowerCase());
        });

        if (filteredItems?.length > 0) {
          return { ...section, items: filteredItems };
        }
        return null;
      })
      .filter(Boolean);
  };

  const filterNavItemsByBillingType = (navData, billingType) => {
    return navData.map((section) => {
      if (section?.items) {
        const updatedItems = section?.items?.map((item) => {
          if (item?.title?.toLowerCase() == 'accounting' && item?.children) {
            const filteredChildren = item?.children?.filter((child) => {
              if (child?.title == 'VAT Declaration') {
                return billingType?.effective;
              }
              if (child?.title == 'Balance Tax Rate') {
                return billingType?.netTaxRate;
              }
              return true;
            });
            return { ...item, children: filteredChildren };
          }
          if (item?.title?.toLowerCase() == 'reports' && item?.children) {
            const filteredChildren = item?.children?.filter((child) => {
              if (child?.title == 'VAT Report') {
                return billingType?.effective || billingType?.netTaxRate;
              }
              return true;
            });
            return { ...item, children: filteredChildren };
          }
  
          return item;
        });
        return { ...section, items: updatedItems };
      }
      return section;
    });
  };

  const filteredNavData = filterNavData(navData, premissionAgainstRole, selectedBranch?.role);
  const finalNavData = filterNavItemsByBillingType(filteredNavData, billingType);

  const isValidPath = (pathname, filteredNavData) => {
    return filteredNavData?.some((section) =>
      section?.items?.some((item) => {
        if (item?.path === pathname) {
          return true;
        }
        if (item?.children) {
          return item?.children?.some((child) => child?.path === pathname || pathname?.startsWith(child?.path));
        }
        return false;
      })
    );
  };

  useEffect(() => {
    if (currentUser?.userType === 1 && !isValidPath(pathname, superAdminData)) {
      navigate(paths.superAdmin.root);
    } else if (currentUser?.userType != 1 && !isValidPath(pathname, finalNavData)) {
      navigate(paths.dashboard.root);
    }
  }, [pathname,finalNavData, navigate]);

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_MINI },
      }}
    >
      <NavToggleButton
        sx={{
          top: 22,
          left: NAV.W_MINI - 12,
        }}
      />

      <Stack
        sx={{
          pb: 2,
          height: 1,
          position: 'fixed',
          width: NAV.W_MINI,
          borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          ...hideScroll.x,
        }}
      >
        <Logo sx={{ mx: 'auto', my: 2 }} />
        {currentUser?.userType && currentUser?.userType == 1 ?
          <NavSectionMini
            data={superAdminData}
            config={{
              currentRole: 'superAdmin',
            }}
          />
          :
          <NavSectionMini
            data={finalNavData}
            config={{
              currentRole: 'admin',
            }}
          />
        }
      </Stack>
    </Box>
  );
}
