import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import taxServices from './taxServices';

const initialState = {
  taxes: [],
  taxWithoutPagination:[],
  totalRecord: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
};

export const addTax = createAsyncThunk('addTax', async (data, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await taxServices.addTax(user.accesstoken, data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const taxGet = createAsyncThunk(
  'taxGet',
  async ({ pageno, branch, limit }, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await taxServices.taxGet(user.accesstoken,pageno,limit,branch);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateTax = createAsyncThunk(
  'updateTax',
  async ({ id, data }, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await taxServices.updateTax(user.accesstoken, id, data);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const taxWithoutPaginationGet = createAsyncThunk(
  'taxWithoutPaginationGet',
  async (branch,thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await taxServices.taxWithoutPaginationGet(user.accesstoken,branch);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const taxSlice = createSlice({
  name: 'tax',
  initialState,
  reducers: {
    resets: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addTax.pending, (state) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(addTax.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.taxes = state.taxes.concat({ ...action?.payload?.body });
      })
      .addCase(addTax.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(taxGet.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(taxGet.fulfilled, (state, action) => {
        state.taxes = action?.payload?.body;
        state.totalRecord = action?.payload?.total_record;
        state.isSuccess = true;
        state.isLoading = false;
      })
      .addCase(taxGet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.accountChart = [];
      })
      .addCase(updateTax.pending, (state) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(updateTax.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.taxes = state.taxes.map((item) =>
          item._id === action?.payload?.body?._id ? action?.payload?.body : item
        );
      })
      .addCase(updateTax.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(taxWithoutPaginationGet.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(taxWithoutPaginationGet.fulfilled, (state, action) => {
        state.taxWithoutPagination = action?.payload?.body;
        state.isSuccess = true;
        state.isLoading = false;
      })
      .addCase(taxWithoutPaginationGet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.taxWithoutPagination = [];
      });
  },
});

export const { resets } = taxSlice.actions;

export default taxSlice.reducer;
