import PropTypes from "prop-types";
// @mui
import Collapse from "@mui/material/Collapse";
import { listClasses } from "@mui/material/List";
import { listItemTextClasses } from "@mui/material/ListItemText";
import { listItemButtonClasses } from "@mui/material/ListItemButton";
// hooks
import { useBoolean } from "src/hooks/use-boolean";
// components
import { NavSectionVertical } from "src/components/nav-section";
import { usePathname } from "src/routes/hooks";
//
import NavItem from "./nav-item";
import useActiveSection from "../useActiveSection";

// ----------------------------------------------------------------------

export default function NavList({ item, nav }) {
  const pathname = usePathname();

  const { path, children } = item;

  const sections = ['Home', 'OurMission', 'WhyChooseUs', "FAQ's",];

  const activeSection = useActiveSection(sections);

  const externalLink = path.includes("http");

  return (
    <>
      <NavItem
        item={item}
        nav={nav}
        open={nav.value}
        onClick={nav.onToggle}
        // active={pathname === path}
        active={activeSection}
        externalLink={externalLink}
      />

      {!!children && (
        <Collapse in={nav.value} unmountOnExit>
          <NavSectionVertical
            data={children}
            sx={{
              [`& .${listClasses.root}`]: {
                "&:last-of-type": {
                  [`& .${listItemButtonClasses.root}`]: {
                    height: 160,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    bgcolor: "background.neutral",
                    backgroundRepeat: "no-repeat",
                    backgroundImage:
                      "url(/assets/illustrations/illustration_dashboard.png)",
                    [`& .${listItemTextClasses.root}`]: {
                      display: "none",
                    },
                  },
                },
              },
            }}
          />
        </Collapse>
      )}
    </>
  );
}

NavList.propTypes = {
  item: PropTypes.object,
};
