import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import dashboardServices from './dashboardServices';

const initialState = {
  dashboard: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
};


export const dashboardGet = createAsyncThunk(
  'dashboardGet',
  async ({branchId,year},thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await dashboardServices.dashboardGet(user.accesstoken,branchId,year);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const superAdminDashboardGet = createAsyncThunk(
  'superAdminDashboardGet',
  async (year,thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await dashboardServices.superAdminDashboardGet(user.accesstoken,year);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    resets: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(dashboardGet.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(dashboardGet.fulfilled, (state, action) => {
        state.dashboard = action?.payload?.body;
        state.isSuccess = true;
        state.isLoading = false;
      })
      .addCase(dashboardGet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.dashboard = {};
      })
      .addCase(superAdminDashboardGet.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(superAdminDashboardGet.fulfilled, (state, action) => {
        state.dashboard = action?.payload?.body;
        state.isSuccess = true;
        state.isLoading = false;
      })
      .addCase(superAdminDashboardGet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.dashboard = {};
      });
  },
});

export const { resets } = dashboardSlice.actions;

export default dashboardSlice.reducer;
