import axios from 'axios';

const BASE_URL = process.env.REACT_APP_HOST_API;

const allCompanyGet = async (token,pageno,limit,search) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/superAdmin/company?page=${pageno}&perPage=${limit}&search=${search??''}`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error('All Company List api failed');
};
const companyGet = async (token,pageno,limit,search) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/company?page=${pageno}&perPage=${limit}&search=${search??''}`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error('Company List api failed');
};
const addCompany = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(`${BASE_URL}/admin/company`, data, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Add Company api failed');
};

const companyServices = {
  allCompanyGet,
  companyGet,
  addCompany
};

export default companyServices;