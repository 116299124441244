import axios from 'axios';

const BASE_URL = process.env.REACT_APP_HOST_API;

const addTax = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(`${BASE_URL}/admin/tax`, data, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Add Tax api failed');
};

const taxGet = async (token, pageno, limit, branch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/tax?branch=${branch}&page=${pageno}&perPage=${limit}`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error('Tax List api failed');
};

const updateTax = async (token, id, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(`${BASE_URL}/admin/tax/${id}`, data, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Updated Tax api failed');
};

const taxWithoutPaginationGet = async (token,branch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/tax/withoutPagination?branch=${branch}`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error('Tax List Without Pagination api failed');
};

const taxServices = {
    addTax,
    taxGet,
    updateTax,
    taxWithoutPaginationGet
};

export default taxServices;
