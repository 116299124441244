import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import mailBoxServices from './mailBoxServices';

const initialState = {
  mailboxes: [],
  totalRecord: null,
  mailBoxStatistics: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
};

export const addMailBox = createAsyncThunk('addMailBox', async (data, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await mailBoxServices.addMailBox(user.accesstoken, data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});
export const mailBoxGet = createAsyncThunk(
  'mailBoxGet',
  async ({ pageno, branch, limit, search, status }, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await mailBoxServices.mailBoxGet(user.accesstoken, pageno, limit, branch, search, status);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const mailBoxStatsGet = createAsyncThunk(
  'mailBoxStatsGet',
  async (branch, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await mailBoxServices.mailBoxStatsGet(user.accesstoken, branch);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updateMailBox = createAsyncThunk(
  'updateMailBox',
  async ({ id, data }, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await mailBoxServices.updateMailBox(user.accesstoken, id, data);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const downloadMailBoxFiles = createAsyncThunk('downloadMailBoxFiles', async ({ branch, startDate, endDate }, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await mailBoxServices.downloadMailBoxFiles(user.accesstoken, branch, startDate, endDate);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const mailBoxSlice = createSlice({
  name: 'mailBox',
  initialState,
  reducers: {
    resets: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addMailBox.pending, (state) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(addMailBox.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.mailboxes = state.mailboxes.concat({ ...action?.payload?.body });
      })
      .addCase(addMailBox.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(mailBoxGet.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(mailBoxGet.fulfilled, (state, action) => {
        state.mailboxes = action?.payload?.body;
        state.totalRecord = action?.payload?.total_record;
        state.isSuccess = true;
        state.isLoading = false;
      })
      .addCase(mailBoxGet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(mailBoxStatsGet.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(mailBoxStatsGet.fulfilled, (state, action) => {
        state.mailBoxStatistics = action?.payload?.body;
        state.isSuccess = true;
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(mailBoxStatsGet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateMailBox.pending, (state) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(updateMailBox.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.mailboxes = state.mailboxes.map((item) =>
          item._id === action?.payload?.body?._id ? action?.payload?.body : item
        );
      })
      .addCase(updateMailBox.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(downloadMailBoxFiles.pending, (state) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(downloadMailBoxFiles.fulfilled, (state) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(downloadMailBoxFiles.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      });
  },
});

export const { resets } = mailBoxSlice.actions;

export default mailBoxSlice.reducer;
