import axios from 'axios';

const BASE_URL = process.env.REACT_APP_HOST_API;

const addMailBox = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(`${BASE_URL}/admin/mailBox`, data, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Add Mail Box api failed');
};
const mailBoxGet = async (token, pageno, limit, branch, search, status) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/mailBox?branch=${branch}&page=${pageno}&perPage=${limit}&search=${search ?? ''}&status=${status ?? ''}`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error('Mail Box List api failed');
};
const mailBoxStatsGet = async (token, branch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/mailBox/stats?branch=${branch}`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error('Mail Box Stats api failed');
};
const updateMailBox = async (token, id, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(`${BASE_URL}/admin/mailBox/${id}`, data, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Updated invoice api failed');
};

const downloadMailBoxFiles = async (token, branch, startDate, endDate) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob',
  };
  const response = await axios.get(
    `${BASE_URL}/admin/mailBox/download/file?branch=${branch}&startDate=${startDate ?? ''}&endDate=${endDate ?? ''}`,
    config
  );
  if (response.status == 200) {
    return response.data;
  }
  throw new Error('Mail Box Download api failed');
};

const mailBoxServices = {
  addMailBox,
  mailBoxGet,
  mailBoxStatsGet,
  updateMailBox,
  downloadMailBoxFiles
};

export default mailBoxServices;
