import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import MainLayout from 'src/layouts/main';
//
import { mainRoutes, HomePage } from './main';
import { authRoutes } from './auth';
import { dashboardRoutes } from './admin';

import { componentsRoutes } from './components';
import { superAdminRoutes } from './superAdmin';


export default function Router() {


  return useRoutes([
    {
      path: '/',
      element: (
        <MainLayout>
          <HomePage />
        </MainLayout>
      ),
    },

    // Auth routes
    ...authRoutes,
  
    // admin routes
    ...dashboardRoutes,
    
    // super admin routes  
    ...superAdminRoutes,
   
    // Main routes
    ...mainRoutes,

    // Components routes
    ...componentsRoutes,
  
    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
