import axios from 'axios';

const BASE_URL = process.env.REACT_APP_HOST_API;

const addGroup = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(`${BASE_URL}/admin/group`, data, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Add Group api failed');
};

const group = async (token,pageno,limit,branch,search,sortField,sortOrder) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/group?branch=${branch}&page=${pageno}&perPage=${limit}&search=${search??""}&sortField=${sortField}&sortOrder=${sortOrder}`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error('Group List api failed');
};

const updateGroup = async (token, id, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(`${BASE_URL}/admin/group/${id}`, data, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Updated Group api failed');
};

const activeGroupGet = async (token,branch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/group/active?branch=${branch}`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error('Active Group Get api failed');
};

const groupServices = {
   addGroup,
   group,
   updateGroup,
   activeGroupGet
};

export default groupServices;
