import axios from 'axios';

const BASE_URL = process.env.REACT_APP_HOST_API;

const login = async (data) => {
  const response = await axios.post(`${BASE_URL}/auth/login`, data);
  if (response?.data) {
    return response.data;
  }
  throw new Error('Login api failed');
};
const register = async (data) => {
  const response = await axios.post(`${BASE_URL}/auth/signup`, data);
  if (response.data) {
    return response.data;
  }
  throw new Error('Register api failed');
};
const verifyEmail = async (data) => {
  const response = await axios.post(`${BASE_URL}/auth/verifyEmail`, data);
  if (response.data) {
    return response.data;
  }
  throw new Error('Verify Email api failed');
};
const forgotPassword = async (data) => {
  const response = await axios.put(
    `${BASE_URL}/auth/forgotpassword`,
    data,
  );
  if (response.data) {
    return response.data;
  }
  throw new Error("ForgotPassword api failed");
};
const verifyOTP = async (data) => {
  const response = await axios.put(
    `${BASE_URL}/auth/validatepin`,
    data,
  );
  if (response.data) {
    return response.data;
  }
  throw new Error("ValidatePin api failed");
};
const resetpassword = async (data) => {
  const response = await axios.put(
    `${BASE_URL}/auth/resetpassword`,
    data,
  );
  if (response.data) {
    return response.data;
  }
  throw new Error("ResetPassword api failed");
};
const verifyAccountOTP = async (data) => {
  const response = await axios.put(
    `${BASE_URL}/auth/verifyAccountOTP`,
    data,
  );
  if (response.data) {
    return response.data;
  }
  throw new Error("verifyAccount OTP api failed");
};

const authService = {
  login,
  register,
  verifyEmail,
  forgotPassword,
  verifyOTP,
  resetpassword,
  verifyAccountOTP
};

export default authService;
