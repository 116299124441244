import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { logout } from '../auth/authSlice';
import branchServices from './branchServices';

const initialState = {
  branches: [],
  selectedBranch: {},
  branchDetail: {},
  singleBranch: {},
  totalRecord: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
};


export const branchDetailGet = createAsyncThunk(
  'branchDetailGet',
  async (branch, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await branchServices.branchDetailGet(user.accesstoken, branch);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const branchGet = createAsyncThunk(
  'branchGet',
  async (thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await branchServices.branchGet(user.accesstoken);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const singleBranchGet = createAsyncThunk('singleBranchGet', async (id, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await branchServices.singleBranchGet(user.accesstoken, id);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});
export const updateBranch = createAsyncThunk('updateBranch', async ({ id, data }, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await branchServices.updateBranch(user.accesstoken, id, data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const branchSlice = createSlice({
  name: 'branch',
  initialState,
  reducers: {
    resets: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = '';
    },
    setSelectedBranch: (state, action) => {
      state.selectedBranch = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(branchDetailGet.pending, (state) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(branchDetailGet.fulfilled, (state, action) => {
        state.branchDetail = action?.payload?.body;
        state.isSuccess = true;
        state.isLoading = false;
      })
      .addCase(branchDetailGet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.branchDetail = {};
      })
      .addCase(branchGet.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(branchGet.fulfilled, (state, action) => {
        state.branches = action.payload.body;
        state.totalRecord=action.payload.total_record;
        state.isSuccess = true;
        state.isLoading = false;
      })
      .addCase(branchGet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(singleBranchGet.pending, (state) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(singleBranchGet.fulfilled, (state, action) => {
        state.singleBranch = action?.payload?.body;
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(singleBranchGet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(updateBranch.pending, (state) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(updateBranch.fulfilled, (state, action) => {
        state.selectedBranch = {
          ...action?.payload?.body
        };
        state.branches = state.branches.map((item) =>
          item._id === action?.payload?.body?._id ? action?.payload?.body : item
        );
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(updateBranch.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(logout, () => initialState);
  },
});

export const { resets, setSelectedBranch } = branchSlice.actions;

export default branchSlice.reducer;
