import { _mock } from 'src/_mock';
import { useSelector } from 'react-redux';

export function useMockedUser() {
  // Use the useSelector hook to select the user from the Redux store


  // Return the user fetched from the Redux store
  return { };
}
