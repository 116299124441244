import axios from 'axios';

const BASE_URL = process.env.REACT_APP_HOST_API;

const addRole = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(`${BASE_URL}/admin/role`, data, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Add Role api failed');
};

const role = async (token, pageno, limit, company) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/role?company=${company}&page=${pageno}&perPage=${limit}`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error('Role List api failed');
};

const updateRole = async (token, id, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(`${BASE_URL}/admin/role/${id}`, data, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Updated Role api failed');
};

const activeRoleGet = async (token,company) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/active/role?company=${company}`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error('Active Role Get api failed');
};

const roleServices = {
    addRole,
    role,
    updateRole,
    activeRoleGet
};

export default roleServices;
