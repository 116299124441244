import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import fiscalYearServices from './fiscalYearServices';

const initialState = {
  fiscalYears: [],
  totalRecord: null,
  fiscalYearQuarters: [],
  fiscalYearHalves:[],
  fiscalYearByYears:[],
  billingType:{},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
};

export const addFiscalYear = createAsyncThunk('addFiscalYear', async (data, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await fiscalYearServices.addFiscalYear(user.accesstoken, data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const fiscalYearGet = createAsyncThunk(
  'fiscalYearGet',
  async ({ pageno, branch, limit }, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await fiscalYearServices.fiscalYear(user.accesstoken, pageno, limit, branch);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateFiscalYear = createAsyncThunk(
  'updateFiscalYear',
  async ({ id, data }, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await fiscalYearServices.updateFiscalYear(user.accesstoken, id, data);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const fiscalYearQuarter = createAsyncThunk(
  'fiscalYearQuarter',
  async (branch, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await fiscalYearServices.fiscalYearQuarter(user.accesstoken, branch);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const fiscalYearHalf = createAsyncThunk(
  'fiscalYearHalf',
  async (branch, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await fiscalYearServices.fiscalYearHalves(user.accesstoken, branch);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const fiscalYearsByYear = createAsyncThunk(
  'fiscalYearsByYear',
  async (branch, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await fiscalYearServices.fiscalYearsByYear(user.accesstoken, branch);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const billingTypeCheck = createAsyncThunk(
  'billingTypeCheck',
  async (branch, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await fiscalYearServices.billingTypeCheck(user.accesstoken, branch);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const fiscalYearSlice = createSlice({
  name: 'fiscalYear',
  initialState,
  reducers: {
    resets: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addFiscalYear.pending, (state) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(addFiscalYear.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.fiscalYears = state.fiscalYears.concat({ ...action?.payload?.body });
      })
      .addCase(addFiscalYear.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(fiscalYearGet.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(fiscalYearGet.fulfilled, (state, action) => {
        state.fiscalYears = action?.payload?.body;
        state.totalRecord = action?.payload?.total_record;
        state.isSuccess = true;
        state.isLoading = false;
      })
      .addCase(fiscalYearGet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.fiscalYears = [];
      })
      .addCase(updateFiscalYear.pending, (state) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(updateFiscalYear.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.fiscalYears = state.fiscalYears.map((item) =>
          item._id === action?.payload?.body?._id ? action?.payload?.body : item
        );
      })
      .addCase(updateFiscalYear.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(fiscalYearQuarter.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(fiscalYearQuarter.fulfilled, (state, action) => {
        state.fiscalYearQuarters = action?.payload?.body;
        state.isSuccess = true;
        state.isLoading = false;
      })
      .addCase(fiscalYearQuarter.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.fiscalYearQuarters = [];
      })
      .addCase(fiscalYearHalf.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(fiscalYearHalf.fulfilled, (state, action) => {
        state.fiscalYearHalves = action?.payload?.body;
        state.isSuccess = true;
        state.isLoading = false;
      })
      .addCase(fiscalYearHalf.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.fiscalYearHalves = [];
      })
      .addCase(fiscalYearsByYear.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(fiscalYearsByYear.fulfilled, (state, action) => {
        state.fiscalYearByYears = action?.payload?.body;
        state.isSuccess = true;
        state.isLoading = false;
      })
      .addCase(fiscalYearsByYear.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.fiscalYearByYears = [];
      })
      .addCase(billingTypeCheck.pending, (state) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(billingTypeCheck.fulfilled, (state, action) => {
        state.billingType = action?.payload?.body;
        state.isSuccess = true;
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(billingTypeCheck.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { resets } = fiscalYearSlice.actions;

export default fiscalYearSlice.reducer;
