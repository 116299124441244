import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import reportServices from './reportServices';

const initialState = {
  incomeStatement: [],
  balanceSheet:[],
  totalRecord: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
};


export const incomeStatementGet = createAsyncThunk(
  'incomeStatementGet',
  async ({pageno, branch, limit,startDate,endDate,refStartDate,refEndDate},thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await reportServices.incomeStatementGet(user.accesstoken,pageno, branch, limit,startDate,endDate,refStartDate,refEndDate);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);


export const balanceSheetGet = createAsyncThunk(
  'balanceSheetGet',
  async ({pageno, branch, limit,balanceSheetPer,comparativeDataAs,accountType},thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await reportServices.balanceSheetGet(user.accesstoken,pageno, branch, limit,balanceSheetPer,comparativeDataAs,accountType);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    resets: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(incomeStatementGet.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(incomeStatementGet.fulfilled, (state, action) => {
        state.incomeStatement = action?.payload?.body;
        state.totalRecord = action?.payload?.total_record;
        state.isSuccess = true;
        state.isLoading = false;
      })
      .addCase(incomeStatementGet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.incomeStatement = [];
      })
      .addCase(balanceSheetGet.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(balanceSheetGet.fulfilled, (state, action) => {
        state.balanceSheet = action?.payload?.body;
        state.totalRecord = action?.payload?.total_record;
        state.isSuccess = true;
        state.isLoading = false;
      })
      .addCase(balanceSheetGet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.balanceSheet = [];
      });
  },
});

export const { resets } = reportSlice.actions;

export default reportSlice.reducer;
