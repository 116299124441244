import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import accountChartServices from './accountChartServices';

const initialState = {
  accountChart: [],
  activeAccountChart: [],
  totalRecord: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
};

export const addAccountChart = createAsyncThunk('addAccountChart', async (data, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await accountChartServices.addAccountChart(user.accesstoken, data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const accountChartGet = createAsyncThunk(
  'accountChartGet',
  async ({ pageno, branch, limit,search,sortField,sortOrder}, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await accountChartServices.accountChart(user.accesstoken,pageno,limit,branch,search,sortField,sortOrder);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateAccountChart = createAsyncThunk(
  'updateAccountChart',
  async ({ id, data }, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await accountChartServices.updateAccountChart(user.accesstoken, id, data);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const activeAccountChart = createAsyncThunk('activeAccountChart', async (thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await accountChartServices.activeAccountChart(user.accesstoken);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const accountChartSlice = createSlice({
  name: 'accountChart',
  initialState,
  reducers: {
    resets: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addAccountChart.pending, (state) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(addAccountChart.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.accountChart = state.accountChart.concat({ ...action?.payload?.body });
      })
      .addCase(addAccountChart.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(accountChartGet.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(accountChartGet.fulfilled, (state, action) => {
        state.accountChart = action?.payload?.body;
        state.totalRecord = action?.payload?.total_record;
        state.isSuccess = true;
        state.isLoading = false;
      })
      .addCase(accountChartGet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.accountChart = [];
      })
      .addCase(updateAccountChart.pending, (state) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(updateAccountChart.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.accountChart = state.accountChart.map((item) =>
          item._id === action?.payload?.body?._id ? action?.payload?.body : item
        );
      })
      .addCase(updateAccountChart.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(activeAccountChart.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(activeAccountChart.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.activeAccountChart = action?.payload?.body;
      })
      .addCase(activeAccountChart.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
        state.activeAccountChart = [];
      });
  },
});

export const { resets } = accountChartSlice.actions;

export default accountChartSlice.reducer;
