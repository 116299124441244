import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import invoiceServices from './invoiceServices';

const initialState = {
  invoices:[],
  invoiceStatistics:{},
  singleInvoice:{},
  totalRecord: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
};

export const addInvoice = createAsyncThunk('addInvoice', async (data, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await invoiceServices.addInvoice(user.accesstoken, data);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
});
export const invoiceGet = createAsyncThunk(
    'invoiceGet',
    async ({pageno,limit,branch,type,search,startDate,endDate,status,sortField,sortOrder}, thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem('currentUser'));
        return await invoiceServices.invoiceGet(user.accesstoken,pageno,limit,branch,type,search,startDate,endDate,status,sortField,sortOrder);
      } catch (error) {
        const message =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);
export const invoiceStatsGet = createAsyncThunk(
  'invoiceStatsGet',
  async ({branch,type}, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await invoiceServices.invoiceStatsGet(user.accesstoken,branch,type);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const invoiceSingleGet = createAsyncThunk(
  'invoiceSingleGet',
  async (id, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await invoiceServices.invoiceSingleGet(user.accesstoken,id);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updateInvoice = createAsyncThunk(
  'updateInvoice',
  async ({id,data}, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await invoiceServices.updateInvoice(user.accesstoken,id,data);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const invoiceDetailGet = createAsyncThunk(
  'invoiceDetailGet',
  async (id, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await invoiceServices.invoiceDetailGet(user.accesstoken,id);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deleteInvoice = createAsyncThunk(
  "deleteInvoice",
  async (id, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await invoiceServices.deleteInvoice(user.accesstoken,id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const invoiceSlice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    resets: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addInvoice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(addInvoice.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(addInvoice.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(invoiceGet.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(invoiceGet.fulfilled, (state, action) => {
        state.invoices = action?.payload?.body;
        state.totalRecord = action?.payload?.total_record;
        state.isSuccess = true;
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(invoiceGet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(invoiceStatsGet.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(invoiceStatsGet.fulfilled, (state, action) => {
        state.invoiceStatistics = action?.payload?.body;
        state.isSuccess = true;
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(invoiceStatsGet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(invoiceSingleGet.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(invoiceSingleGet.fulfilled, (state, action) => {
        state.singleInvoice = action?.payload?.body;
        state.isSuccess = true;
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(invoiceSingleGet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateInvoice.pending, (state) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(updateInvoice.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(updateInvoice.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(invoiceDetailGet.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(invoiceDetailGet.fulfilled, (state, action) => {
        state.singleInvoice = action?.payload?.body;
        state.isSuccess = true;
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(invoiceDetailGet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteInvoice.pending, (state) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(deleteInvoice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.invoices = state.invoices.filter((invoice) => invoice?._id !== action?.payload?.body?._id);
      })
      .addCase(deleteInvoice.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      });
  },
});

export const { resets } = invoiceSlice.actions;

export default invoiceSlice.reducer;
