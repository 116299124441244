import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import companyServices from './companyServices';

const initialState = {
  companies: [],
  totalRecord: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
};


export const allCompanyGet = createAsyncThunk(
  'allCompanyGet',
  async ({ pageno, limit, search }, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await companyServices.allCompanyGet(user.accesstoken, pageno, limit, search);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const companyGet = createAsyncThunk(
  'companyGet',
  async ({ pageno, limit, search }, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await companyServices.companyGet(user.accesstoken, pageno, limit, search);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const addCompany = createAsyncThunk('addCompany', async (data, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await companyServices.addCompany(user.accesstoken, data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    resets: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(allCompanyGet.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(allCompanyGet.fulfilled, (state, action) => {
        state.companies = action?.payload?.body;
        state.totalRecord = action?.payload?.total_record;
        state.isSuccess = true;
        state.isError = false;
        state.isLoading = false;
      })
      .addCase(allCompanyGet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.companies = [];
      })
      .addCase(companyGet.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(companyGet.fulfilled, (state, action) => {
        state.companies = action?.payload?.body;
        state.totalRecord = action?.payload?.total_record;
        state.isSuccess = true;
        state.isError = false;
        state.isLoading = false;
      })
      .addCase(companyGet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.companies = [];
      })
      .addCase(addCompany.pending, (state) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(addCompany.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.companies = state.companies.concat({ ...action?.payload?.body });
      })
      .addCase(addCompany.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      });
  },
});

export const { resets } = companySlice.actions;

export default companySlice.reducer;
